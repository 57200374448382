<template>
  <div>
    <b-overlay :show="$apollo.loading || mutationLoading">
      <b-card>
        <validation-observer
          ref="uploadForm"
          tag="form"
        >
          <b-row>
            <b-card-header
              class="justify-content-start py-0 px-1 pb-1 mb-1 border-bottom align-items-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2 flex-grow-1">
                Add Growth Version
                <b-badge
                  class="font-weight-normal ml-50"
                  variant="primary"
                >
                  {{ btns[0].title }}
                </b-badge>
              </h3>
            </b-card-header>
            <b-col cols="12">
              <b-form-group
                label="Title"
                label-size="sm"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Title"
                    required
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-size="sm"
              >
                <b-form-textarea
                  v-model="description"
                  placeholder="Description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <h5><strong>Answer the Following Parameters</strong></h5>
            </b-col>
          </b-row>
          <b-row
            v-for="(question, index) in btns[0].growth_parameters"
            :key="index"
          >
            <b-col>
              <b-form-group
                :label="`Q. ${question.title} ${question.is_required ? '*' : ''}`"
                :label-class="`h5`"
                :label-for="`question-${index}`"
                class="mb-2"
              >
                <template #description>
                  <small
                    v-if="!readonly && question.input_type === 'Document' && question.response "
                    class="font-weight-bold"
                  >File already uploaded. Uploading new file will replace existing file.</small>
                </template>
                <b-input-group class="mt-50">
                  <b-form-textarea
                    v-if="question.input_type === 'Long text'"
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :readonly="readonly"
                    placeholder="Your answer"
                    required
                  />
                  <v-select
                    v-else-if="question.input_type === 'Dropdown' || question.input_type === 'Multiselect'"
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :style="readonly?'pointer-events:none;filter:grayscale(100%)':''"
                    :multiple="question.input_type === 'Multiselect'"
                    :options="JSON.parse(question.options)"
                    append-to-body
                    class="w-100"
                    label="Choose from the following"
                    placeholder="Select from list"
                  />
                  <b-form-file
                    v-else-if="readonly !== true && question.input_type==='Document'"
                    :id="`answer-${index}`"
                    v-model="files[question.id]"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here... [Max: 5MB]"
                    @input="checkFileSize(files[question.id], question.id)"
                  />
                  <b-form-input
                    v-else
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :readonly="readonly"
                    :type="question.input_type === 'Number' ? 'number' : 'text'"
                    placeholder="Your answer"
                    required
                  />
                  <b-input-group-append
                    v-if="question.input_type==='Document' && question.response && !files[question.id]"
                  >
                    <Promised
                      :promise="getLinkFromS3(question.response)"
                    >
                      <template #pending>
                        <b-button
                          class="mr-50"
                          disabled
                          size="sm"
                          variant="primary"
                        >
                          <feather-icon icon="ClockIcon" />
                        </b-button>
                      </template>
                      <template #default="data">
                        <b-button
                          v-b-tooltip="'View Uploaded File'"
                          :href="data"
                          class="mr-50"
                          target="_blank"
                          variant="primary"
                        >Open
                        </b-button>
                      </template>
                      <template #rejected>
                        <span v-b-tooltip="`File not found`">
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="outline-danger"
                          ><feather-icon icon="AlertTriangleIcon" /></b-button>
                        </span>
                      </template>
                    </Promised>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                @click="addNewVersion()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-overlay>
  <!-- <b-modal
      id="selectStartup"
      ref="selectStartupModal"
      title="Select Startup"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        v-if="userStartups && userStartups.length > 0"
        :label-class="`h5`"
        label="Select Startup"
      >
        <v-select
          id="selectStartup"
          v-model="selectStartup"
          :get-option-label="e => e.users_organizationtable.title"
          :options="userStartups"
          :reduce="startup => startup.organization_id"
          placeholder="Choose one from the list"
          @input="$router.replace({name: $route.name, params: {aid: selectStartup }}); $refs.selectStartupModal.hide()"
        />
      </b-form-group>
      <div
        v-if="userStartups && userStartups.length > 0"
        class="d-flex justify-content-center align-items-center"
      >
        <hr class="w-100 mr-2">
        <span class="px-2 small text-muted font-weight-bold">OR</span>
        <hr class="w-100 ml-2">
      </div>
      <b-button
        :to="{name: 'startup-portal-details', query: { redirect: encodeURIComponent($route.fullPath) }}"
        class="my-50"
        block
        variant="flat-primary"
      >
        Add Startup
      </b-button>
    </b-modal> -->
  </div>
</template>
<script>
import { getUserData } from '@/utils/auth'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
// import { VueGoodTable } from 'vue-good-table'
// import { ChevronsRightIcon } from 'vue-feather-icons'
import gql from 'graphql-tag'
import { Promised } from 'vue-promised'
import {
  BCard,
  // BCardBody,
  // BCardSubTitle,
  // BPagination,
  // BCardText,
  BButton,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  // BCardTitle,
  BCol,
  // BLink,
  // BModal,
  // BTable,
  // BFormCheckbox,
  // BFormFile,
  // BAlert,
  BCardHeader,
  BBadge,
  // BCardFooter,
  // BImg,
  // BFormSelect,
  // BOverlay,
  // BRow,
  // BTabs,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    // BTabs,
    BCard,
    BButton,
    vSelect,
    // BCardText,
    // VueGoodTable,
    // BCardBody,
    // BPagination,
    // BAlert,
    // BCardTitle,
    Promised,
    // BFormFile,
    // BFormCheckbox,
    BFormTextarea,
    // BCardSubTitle,
    // BOverlay,
    BCardHeader,
    BFormGroup,
    // BFormSelect,
    BFormInput,
    // BImg,
    // BCardFooter,
    // BTable,
    // BLink,
    BCol,
    BBadge,
    // BRow,
    // BModal,
    // ChevronsRightIcon,
    // PlusIcon,
    // CompassIcon,
    ValidationObserver,
    ValidationProvider,
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.resource === 'Auth' || getUserData().associatedOrgDetails) {
      next()
    }
    next(false)
  },
  data() {
    return {
      pageLength: 5,
      milestones: [],
      searchTerm: '',
      newload: true,
      selectedBd: null,
      selectedGrowthSet: null,
      btns: [],
      vbd: [],
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Badge',
          field: 'badge',
        },
        {
          label: 'Date Added',
          field(row) {
            const dateNew = new Date(row.created_at)
            const options = { day: 'numeric', month: 'long', year: 'numeric' }
            const formattedDate = dateNew.toLocaleDateString('en-US', options)
            return formattedDate
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      title: null,
      description: '',
      is_internal: true,
      file_link: null,
      file: null,
      buttons: [
        { caption: 'Toggle 1', state: true },
        { caption: 'Toggle 2', state: false },
        { caption: 'Toggle 3', state: false },
        { caption: 'Toggle 4', state: false },
      ],
      programs: [],
      applynow: [],
      items: [
        {
          'Organized By': 'xyz', 'Title ': 'Startup India', 'Type ': 'Online', 'Sector ': 'xyz', 'Funding Assitance': 'xyz', 'Lifecycle Stage': 'xyz', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Duration ': 1000,
        },
      ],
      applications: [],
      mentorprograms: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      selectedIndex: null,
      userID: getUserData().id,
      mutationLoading: false,
      selectedOrgId: getUserData().associatedOrgDetails[0].organization_id,
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    handleCheck() {
      // console.log(this.btns[0].growth_parameters)
    },
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    getvbd() {
      this.$apollo.queries.vbd.refetch()
    },
    getuploadingdata() {
      const temp = []
      this.btns[0].growth_parameters.forEach(data => {
        temp.push({
          response: data.response,
          growth_parameters_id: data.id,
        })
      })
      this.btns[0].growth_custom_parameters.forEach(data => {
        temp.push({
          response: data.response,
          growth_custom_parameters_id: data.id,
        })
      })
      return temp
    },
    async addNewVersion() {
      // console.log(this.btns[0])
      this.mutationLoading = true
      const mutation = gql`
        mutation($data: [growth_parameters_startup_entry_insert_input!]!) {
          insert_growth_category_startup_entry_one (
            object: {
              startup_id: ${this.selectedOrgId},
              title: ${this.title},
              description: ${this.description},
              growth_categories_id: ${this.selectedBd},
              growth_parameters_startup_entries:{
                data: $data
              }
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
        variables: {
          data: this.getuploadingdata(),
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully added new version.',
            icon: 'CompassIcon',
            variant: 'success',
          },
        })
        this.$router.back()
        this.mutationLoading = false
      }).catch(error => {
        // console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong. Please try again later.',
            message: error.message,
            icon: 'CompassIcon',
            variant: 'danger',
          },
        })
        this.mutationLoading = false
      })
    },
    deleteversion() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this version?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete Version',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_versions_business_details_by_pk(pk_columns: {id: ${this.selectedIndex}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.vbd.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    btns: {
      query() {
        return gql`
          query Btns {
            growth_categories(where: {id: {_eq: ${this.$route.params.gid}}}) {
              id
              title
              growth_custom_parameters(where: {startup_id: {_eq: ${this.selectedOrgId}}}, order_by: {id: asc}) {
                id
                is_private
                parameter
                input_type
              }
              growth_parameters(order_by: {id: asc}) {
                id
                title
                input_type
              }
            }
          }`
      },
      update(data) {
        this.selectedBd = data.growth_categories[0].id
        return data.growth_categories
      },
    },
    startupOrgs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        if (data.users_organizationtable.length === 1) {
          this.selectedOrgId = data.users_organizationtable[0].users_associationtables.id
        }
        data.users_organizationtable.forEach(orgs => {
          if (orgs.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
